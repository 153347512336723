import React from "react"
import Layout from "../components/layout";
import Header from "../components/header"
import {Col, Row} from "reactstrap";

const Coaching = () => {

    return <Layout>
        <Row>
            <Col>
                <h1>Coaching </h1>
                <p>
                    Większość osób podchodzi do tematu coachingu z dystansem. Często jest to stanowisko uzasadnione.
                    Warto jednak pamiętać, że odpowiedzi na najbardziej nurtujące nas pytania najczęściej są w nas
                    samych, jednak niewłaściwe pytania prowadzą do niewłaściwych odpowiedzi.

                    Miałem okazję przeprowadzać kilkaset spotkań coachingowych oraz analiz biznesowych. Dziś jestem
                    przekonany, że poziom naszego doświadczenia jest uzależniony przede wszystkim od praktyki i ludzi,
                    których spotykamy na naszej drodze. Chętnie poszerzę bazę swoich odniesień, dlatego osoby
                    zainteresowane zapraszam do kontaktu.
                </p>
            </Col>
            <Col className="text-center">
                <img className="H__img" src="/images/hand.webp"/>
            </Col>
        </Row>
    </Layout>
}

export default Coaching;

